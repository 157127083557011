<template>
  <div>
      <div class="buyer-chart-box">
    <div id="chart">
      <apexchart
        type="radialBar"
        height="320"
        :options="BuyerSeller.chartOptions"
        :series="BuyerSeller.series"
        :states="BuyerSeller.states"
      ></apexchart>
    </div>
    </div><!--buyer-chart-box-->
  </div>
</template>



<script>
export default {
    name:'BuyerSellerChart',
    props: {
        BuyerSeller: Object,
    }
}
</script>