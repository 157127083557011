
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
        <div class="wrapper buyerseller-wrapper">
          <div class="row wrapper-inner-row align-items-center">
            <div class="col-xl-4 col-lg-4 col-md-4">
              <div class="currency-select-info">
                <!--currey-select-box-->
                <div class="currency-info">
                  <div v-if="allCurrency.length != 0" class="currey-select-box">
                    <select
                      class="w-100 py-2 border-0 mb-0"
                      v-model="modelvalue"
                    >
                      <option
                        v-for="(currency, index) in allCurrency"
                        :key="index"
                        :value="currency"
                      >
                        {{ currency.name }}
                      </option>
                    </select>
                  </div>
                  <div v-else class="currey-select-box"><h2>No Orders</h2></div>
                  <ul class="currency-list">
                    <li v-for="(li_data, index) in modelvalue.li" :key="index">
                      {{ li_data.key }}
                      <span>{{
                        li_data.key == "Amount" || li_data.key == "Balance"
                          ? parseFloat(li_data.value).toFixed(5)
                          : li_data.value
                      }}</span>
                    </li>
                  </ul>
                </div>
                <!--currency-info-->
              </div>
              <!--currency-select-info-->
            </div>
            <!--col-xl-4 col-lg-4 col-md-4-->

            <div
              class="col-xl-4 col-lg-4 col-md-4"
              v-for="(buyerseller, index) in BuyerSellerData"
              :key="index"
            >
              <BuyerSellerChart :BuyerSeller="buyerseller" />
            </div>
            <!--col-xl-4 col-lg-4 col-md-4-->
          </div>
          <!--row wrapper-inner-row-->
        </div>
        <!--wrapper buyerseller-wrapper-->
      </div>
      <!--col-xl-12 col-lg-12 col-md-12-->

      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        class="p-datatable-customers"
        :rows="per_page"
        :totalRecords="totalRows"
        @page="onPage($event)"
        @sort="onSort($event)"
        dataKey="id"
        :rowHover="true"
        :filters="filters"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="filterPage"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <!--:rowsPerPageOptions="[10, 25, 50]"-->
        <template #header>
          <div class="table-header">
            List of Orders
            <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <Dropdown v-model="filters.duration" :options="duration"  @change="topFilter($event.value,'duration')" placeholder="Select a type" class="p-column-filter" :showClear="true">
                <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
            </Dropdown>
               
            </span> -->
          </div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column
          field="name"
          header="Name"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Name</span>

            {{ slotProps.data.user.name }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.name"
              @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>
        <Column
          field="order_type"
          header="Type"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Type</span>
            <span :class="'customer-badge name-' + slotProps.data.order_type">{{
              slotProps.data.order_type
            }}</span>
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown
              v-model="filters.type"
              @change="topFilter($event.value, 'type')"
              :options="order_type"
              placeholder="Select a type"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown> </template
          >>
        </Column>
        <Column
          field="currency"
          header="Currency"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Currency</span>
            <span
              :class="'customer-badge currency-' + slotProps.data.currency"
              >{{ slotProps.data.currency }}</span
            >
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown
              v-model="filters.coin"
              @change="topFilter($event.value, 'coin')"
              :options="crypto"
              optionValue="currency"
              optionLabel="currency"
              placeholder="Select a currency"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span
                  :class="
                    'customer-badge Currency-' + slotProps.option.currency
                  "
                  >{{ slotProps.option.currency }}</span
                >
              </template>
            </Dropdown> </template
          >>
        </Column>
        <Column
          header="Quantity"
          :sortable="true"
          sortField="quantity"
          filterField="quantity"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Quantity</span>

            {{ slotProps.data.quantity }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.quantity"
              @keydown.enter="topFilter(filters.quantity, 'quantity')"
              class="p-column-filter"
              placeholder="Search by quantity"
            />
          </template>
        </Column>
        <Column
          header="At Price"
          :sortable="true"
          sortField="at_price"
          filterField="at_price"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">At Price</span>

            {{ slotProps.data.at_price }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.at_price"
              @keydown.enter="topFilter(filters.at_price, 'at_price')"
              class="p-column-filter"
              placeholder="Search by at_price"
            />
          </template>
        </Column>
        <Column
          header="Total"
          :sortable="true"
          sortField="total"
          filterField="total"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Total</span>

            {{ slotProps.data.total }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.total"
              @keydown.enter="topFilter(filters.total, 'total')"
              class="p-column-filter"
              placeholder="Search by total"
            />
          </template>
        </Column>

        <Column
          header="Date"
          :sortable="true"
          sortField="created_at"
          filterField="created_at"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span
              :class="'customer-badge status-' + slotProps.data.created_at"
              >{{
                new Date(slotProps.data.created_at).toLocaleDateString()
              }}</span
            >
          </template>
          <template #filter>
            <Calendar
              v-model="filters.date"
              :showButtonBar="true"
              dateFormat="yy-mm-dd"
              class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')"
              placeholder="Search by date"
            />
          </template>
        </Column>

        <Column
          field="current_status"
          header="Status"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span
              :class="'customer-badge status-' + slotProps.data.current_status"
              >{{ slotProps.data.current_status }}</span
            >
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown
              v-model="filters.status"
              @change="topFilter($event.value, 'status')"
              :options="cryptoStatus"
              optionValue="key"
              optionLabel="value"
              placeholder="Select a Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option.value
                }}</span>
              </template>
            </Dropdown> </template
          >>
        </Column>
      </DataTable>
       <div class="input-box" id="total">
        <div class="container-fluid">
          <div class="row">       
            <div class="col-xl-4 col-lg-4 col-md-4 mx-auto">
              <div class="input-group">
                <!-- <input
                  type="text"
                  class="form-control"
                  :value="total_qty.toFixed(5)"
                  readonly
                  disabled
                /> -->
              </div>
            </div>
            <!--col-xl-4 col-lg-4 col-md-4 mx-auto-->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import ApiClass from "../../../api/api";
import filterPage from "../../../jsonFiles/filterPage.json";
import PageHeader from "@/components/page-header";
import BuyerSellerChart from "@/components/BuyerSellerChart";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
// import MultiSelect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,
    BuyerSellerChart,
     
  },
  watch: {
    modelvalue(v) {
      this.BuyerSellerData[0].series = [parseFloat(v.buyer_total).toFixed(2)];
      this.BuyerSellerData[1].series = [parseFloat(v.seller_total).toFixed(2)];
    },
  },
  data() {
    return {
      filterPage: filterPage.allpages,
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "Orders",
      items: [
        {
          text: "Manage Orders",
          href: "#",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      filters: {
        type: null,
        status: null,
        quantity: null,
        at_price: null,
        total: null,
        coin: null,
        date: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      crypto: [],
      total_qty:0,

      cryptoStatus: [],
      duration: ["day", "week", "month"],
      order_type: ["buy", "sell"],
      currency: [],

      allCurrency: [],
      modelvalue: [],

      symbol: "BTC",

      selectedCurrency: {
        name: "BTC",
        code: "BT",
        li: [
          {
            key: "Name",
            value: "Bitcoin",
          },
          {
            key: "Amount",
            value: "$6193.23",
          },
          {
            key: "Fee",
            value: "0.02%",
          },
          {
            key: "Balance",
            value: "0.25484 BTC",
          },
        ],
      },
      currencyname: [
        {
          name: "BTC",
          code: "BT",
          li: [
            {
              key: "Name",
              value: "Bitcoin",
            },
            {
              key: "Amount",
              value: "$6193.23",
            },
            {
              key: "Fee",
              value: "0.02%",
            },
            {
              key: "Balance",
              value: "0.25484 BTC",
            },
          ],
        },
        {
          name: "BNB",
          code: "BN",
          li: [
            {
              key: "Name",
              value: "Binance",
            },
            {
              key: "Amount",
              value: "$7180.24",
            },
            {
              key: "Fee",
              value: "0.05%",
            },
            {
              key: "Balance",
              value: "0.25910 BNB",
            },
          ],
        },
        {
          name: "LTC",
          code: "LT",
          li: [
            {
              key: "Name",
              value: "Litcoin",
            },
            {
              key: "Amount",
              value: "$5190.45",
            },
            {
              key: "Fee",
              value: "0.08%",
            },
            {
              key: "Balance",
              value: "0.35484 LTC",
            },
          ],
        },
        {
          name: "TRX",
          code: "TR",
          li: [
            {
              key: "Name",
              value: "Tron",
            },
            {
              key: "Amount",
              value: "$4518.60",
            },
            {
              key: "Fee",
              value: "0.10%",
            },
            {
              key: "Balance",
              value: "0.58585 TRX",
            },
          ],
        },
        {
          name: "ETH",
          code: "ET",
          li: [
            {
              key: "Name",
              value: "Ethereum",
            },
            {
              key: "Amount",
              value: "$9584.90",
            },
            {
              key: "Fee",
              value: "0.20%",
            },
            {
              key: "Balance",
              value: "0.98521 ETH",
            },
          ],
        },
      ],

      BuyerSellerData: [
        {
          series: [0],
          chartOptions: {
            chart: {
              height: 320,
              type: "radialBar",
            },
            colors: ["#34c38f"],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "80%",
                },
              },
            },
            labels: ["Buyer"],
            stroke: {
              lineCap: "round",
            },

            states: {
              normal: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: "none",
                  value: 0,
                },
              },
            },
          },
        },

        {
          series: [0],
          chartOptions: {
            chart: {
              height: 320,
              type: "radialBar",
            },
            colors: ["#f46a6a"],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "80%",
                },
              },
            },
            labels: ["Seller"],
            stroke: {
              lineCap: "round",
            },

            states: {
              normal: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              hover: {
                filter: {
                  type: "none",
                  value: 0,
                },
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: "none",
                  value: 0,
                },
              },
            },
          },
        },
      ],
    };
  },
  created() {},
  async mounted() {
    this.client();
    this.getCurrencylist();
    var result = await ApiClass.getRequest("admin/crypto/getlist", true);
    this.crypto = result.data.data.crypto;
    this.cryptoStatus = result.data.data.status;
  },
  methods: {
    async client() {
      await ApiClass.getRequest(
        "admin/users/orders/get?per_page=" +
          this.per_page +
          "&page=" +
          this.page +
          "&" +
          this.filter
      ).then((res) => {
        if (res.data.status_code == 1) {
          this.loading = false;
          this.customers = res.data.data.data;
          // console.log("rahul" + this.customers);
          this.currentPage = res.data.data.current_page;
          this.totalRows = res.data.data.total;
          // this.total_qty = res.data.data.total_qty;
          // console.log("thiss", this.customers);
        }
      });
    },

    async getCurrencylist() {
      await ApiClass.getNodeRequest("dashboard/get-alll-cryptocurrency").then(
        (res) => {
          // console.log("result===", res);
          if (res.data.status_code == 1) {
            this.allCurrency = res.data.data.result;
            console.log(this.allCurrency);

            this.modelvalue = this.allCurrency.find((e) => {
              return e.name == "XRP";
            });
            console.log("modelvalue===", this.modelvalue);
            //Setting chart data
            // this.BuyerSellerData[0].series = [this.modelvalue.buyer_total];
            // this.BuyerSellerData[1].series = [this.modelvalue.seller_total];
          }
        }
      );
    },

    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }
      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }
      this.client();
    },
    onSort(e) {
      
          for (let elKey in this.filters) {
            //console.log(elKey);
             this.filters[elKey] = null;
          } 
  
      this.loading = !this.loading;
      var sortby = e.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + e.sortField;
      
      this.client();
    },
    onFilter() {},

    topFilter(columnVal, columnName) {
      
      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.split("/").reverse().join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.client();
    },
  },
};
</script>
  <style lang="scss">
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-calendar .p-datepicker {
  width: 350px;
}

.p-inputtext {
  width: 100%;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>